<template>
  <div class="col-12">
    <div class="card card-body">
        <div class="g row">
            <div class="form-group col-12 col-lg-4">
              <label for="">اختر المستخدم</label>
              <select class="form-control" v-model="user_id">
                <option value="">-- اختر --</option>
                <option v-for="u in users" :key="u._id" :value="u._id">
                    {{ u.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-lg-2">
                <br>
                <button class="btn btn-primary" @click="createCode()">
                    انشاء الكود
                    <i class="fa fa-arrow-left"></i>
                </button>
            </div>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المستخدم
                    </th>
                    <th>
                        الكود
                    </th>
                    <th>
                        الحالة
                    </th>
                    <th>
                        حذف
                    </th>
                </thead>
                <tbody>
                    <tr v-for="code in codes" :key="code._id">
                        <td>
                            {{ users[code.user_id].name }}
                        </td>
                        <td>
                            {{ code.code }}
                        </td>
                        <td>
                            <span v-if="code.status" class="text-success">تم الاستخدام</span>
                            <span v-if="!code.status" class="text-danger">لم يتم الاستخدام</span>
                        </td>
                        <td>
                            <button class="btn btn-danger fa fa-trash" v-if="!code.status" @click="deleteCode(code._id)"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            codes: [],
            users: {},
            user_id: ""
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/users', {
            jwt: g.user.jwt
        }).then(function(r){
            r.response.forEach(function(a){
                g.users[a._id] = a
            })
            g.getCodes()
        })
    },
    methods: {
        getCodes(){
            var g = this;
            $.post(api + '/admin/codes', {
                jwt: g.user.jwt
            }).then(function(r){
                g.codes = r.response
            })
        },
        createCode(){
            var g = this;
            $.post(api + '/admin/create-code', {
                jwt: g.user.jwt,
                id: g.user_id
            }).then(function(r){
                g.getCodes()
            })
        },
        deleteCode(id){
            var g = this;
            $.post(api + '/admin/delete-code', {
                jwt: g.user.jwt,
                id: id
            }).then(function(r){
                g.getCodes()
            })
        }
    }
}
</script>

<style>

</style>